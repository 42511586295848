.registration-body {
    background-color: #E3EAF8;
}

/* EmployeeChecklist.css */
.modal-body {
    padding: 20px;
  }
  
  .modal-title {
    font-weight: bold;
    font-size: 1.5rem;
  }
  
  .checklist-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .checklist-item input {
    margin-right: 10px;
  }
  
  .checklist-description {
    font-size: 0.9rem;
    color: #555;
  }

    
.registration-card {
    margin:  auto;
    width: 974px;
    background: #fff;
    /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), inset 0px 4px 4px rgba(0, 0, 0, 0.25); */
    border-radius: 37px;
    text-align: center;
    padding-bottom: 20px;
    padding-top: 50px;
    position: relative;
}

.heading {
    font-family: 'ERASDEMIITC';
    color: black;
}

/* Policicue logo */

/* .registration-card img {
    width: 340px;
    height: 60px;
    margin: 30px 0;
} */

.registration-form {
    width: 50%;
}

.cross-icon {
    position: absolute;
    top: 15px;
    right: 15px;
}
.quantity::-webkit-inner-spin-button, 
.quantity::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}
.inputfield {
    width: 350px;
    height: 60px;
    line-height: 50px;
    background: #fafafa;
    font-size: 16px;
    box-shadow: inset 0 1px 3px 0 rgb(0 0 0 / 8%);
    border-radius: 5px;
    padding: 0 20px;
    font-size: 16px;
    color: #666;
    border: none;
}

.signupimg {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.signupimg img {
    width: 652px;
    height: 410px;
}
.verify-otp {    
    margin: 20px;
}

.verifyotp-btn {
    width: 301px;
    height: 46px;
    border: none;
    padding: 10px;
    border-radius: 25px;
    background-color: #0093dd;
    color: #fff;
    font-weight: normal;
    font-size: 1rem;
    font-family: 'ErasDemiITC';
    cursor: pointer;
    /* margin: 20px 100px; */
}
.verifyotp-btn:hover{
    color: #fff;
}
.resend-otp{
    margin-top: 20px;
}
.resend-otp a{
    color: #29166F;
    font-family: 'ERASMEDIUMITC';
}
.privacypolicy-chechbox{
    color: #29166F;
    font-family: 'ERASMEDIUMITC';
}
.signup-btn {
    width: 270px;
    height: 50px;
    border: none;
    border-radius: 25px;
    background-color: #0093dd;
    color: #fff;
    font-weight: normal;
    margin: 20px 0;
    font-family: 'ERASDEMIITC';
    font-size: 1rem;
}

/* .privacypolicy-chechbox{
    margin: 20px 0;
    } */

a {
    text-decoration: none;
}

@media (max-width:865px) {
    .signupimg {
        display: none;
    }
    .registration-card {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .privacypolicy-chechbox {
        width: 350px;
    }
    .already-have-account {
        width: 350px;
        font-family: 'ERASMEDIUMITC';
    }
    .verify-otp {
        width: 350px;
        margin: 20px;
    }
    .signupbtn {
        width: 350px;
    }
    .social-login-btn {
        width: 350px;
    }
}


